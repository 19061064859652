<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>添加新闻</el-breadcrumb-item>
    </el-breadcrumb>
    <hr style="margin-bottom: 20px" />
    <el-form
      ref="formData"
      :model="formData"
      label-width="120px"
      :rules="rules"
    >
      <el-form-item label="所属分类">
        <el-select v-model="formData.cateId" placeholder="请选择">
          <el-option
            v-for="item in cateList"
            :key="item.cateId"
            :label="item.cateName"
            :value="item.cateId"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="标题">
        <el-input v-model="formData.title"></el-input>
      </el-form-item>
      <el-form-item label="简介">
        <el-input v-model="formData.summary"></el-input>
      </el-form-item>
      <el-form-item label="发布时间">
        <el-date-picker
          v-model="formData.addTime"
          type="datetime"
          placeholder="选择日期时间"
          value-format="yyyy-MM-dd HH:mm"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="详情">
        <VueUeditorWrap
          v-model="formData.details"
          :config="myConfig"
          ref="myTextEditor"
        ></VueUeditorWrap>
      </el-form-item>
      <el-form-item label="展示图" class="showImg">
        <send-image type="one" @addImageStr="addImageStr"></send-image>

        <span style="color: red; margin-left: 20px"
          >每张图片不能超过500K，建议尺寸：150*150px</span
        >
      </el-form-item>
      <el-form-item>
        <el-button
          size="mini"
          type="primary"
          style="margin: 20px 20px 0 0"
          @click="sumitForm('formData')"
          >确定</el-button
        >
        <el-button
          size="mini"
          type="primary"
          style="margin: 20px 0"
          @click="$router.go(-1)"
          >返回</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { newsAdd, newsDetail, newsCateAll } from "../../api/news";
import SendImage from "../../components/sendImage.vue";
import VueUeditorWrap from "vue-ueditor-wrap";
export default {
  components: {
    VueUeditorWrap,
    SendImage,
  },
  data() {
    return {
      myConfig: {
        toolbars: [
          [
            "simpleupload", //单图上传
            "fullscreen",
            "source",
            "|",
            "undo",
            "redo",
            "|",
            "bold",
            "italic",
            "underline",
            "fontborder",
            "strikethrough",
            "superscript",
            "subscript",
            "removeformat",
            "formatmatch",
            "autotypeset",
            "blockquote",
            "pasteplain",
            "|",
            "forecolor",
            "backcolor",
            "insertorderedlist",
            "insertunorderedlist",
            "selectall",
            "|",
            "rowspacingtop",
            "rowspacingbottom",
            "lineheight",
            "|",
            "customstyle",
            "paragraph",
            "fontfamily",
            "fontsize",
            "|",
            "directionalityltr",
            "directionalityrtl",
            "indent",
            "|",
            "justifyleft",
            "justifycenter",
            "justifyright",
            "justifyjustify",
            "|",
            "touppercase",
            "tolowercase",
            "|",
            "imagenone",
            "imageleft",
            "imageright",
            "imagecenter",
            "|",
            "emotion",
            "map",
            "insertcode",
            "background",
            "|",
            "horizontal",
            "date",
            "time",
            "spechars",
            "|",
            "inserttable",
            "deletetable",
            "insertparagraphbeforetable",
            "insertrow",
            "deleterow",
            "insertcol",
            "deletecol",
            "mergecells",
            "mergeright",
            "mergedown",
            "|",
            "print",
            "preview",
          ],
        ],
        autoHeightEnabled: false, // 编辑器不自动被内容撑高
        initialFrameHeight: 400, // 初始容器高度
        initialFrameWidth: "60%", // 初始容器宽度
        //  serverUrl: "/api/config",
        serverUrl: "/config", //打包
        //          UEDITOR_HOME_URL: "/UEditor/",
        UEDITOR_HOME_URL: "/static/UEditor/", //打包
        maximumWords: "100000000",
      },
      cateList: [],
      formData: {
        newsId: "0",
        cateId: "",
        title: "",
        summary: "",
        addTime: "",
        imgUrl: "",
        details: "",
      },
    };
  },
  created() {
    this.formData.newsId = this.$route.query.id;
    if(this.$route.query.id){
      this.getFormDetail();
    }
     this.getCateList()
  },

  methods: {
    async getCateList() {
      const {data} = await newsCateAll();
      this.cateList = data.data
    },
    addImageStr(image) {
      this.formData.imgUrl = image;
      console.log(image)
    },
    async getFormDetail() {
      const { data } = await newsDetail({
        newsId: this.formData.newsId,
      });
      if (data.code == 0) {
        this.formData = data.data[0];
      }
    },
    async updatedForm() {
      let that = this;
      const { data } = await newsAdd(this.formData);
      if (data.code == 0) {
        this.$message({
          type: "success",
          message: data.msg,
        });
        setTimeout(() => {
          that.$router.go(-1);
        }, 500);
      } else {
        this.$message({
          type: "error",
          message: data.msg,
        });
      }
    },
    sumitForm(formData) {
      this.$refs[formData].validate((valid) => {
        if (valid) {
          this.updatedForm();
        } else {
          this.updatedForm();
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.el-input__inner {
  width: 300px !important;
}
/deep/.el-textarea__inner {
  width: 300px !important;
}
.title {
  font-weight: bold;
  margin-left: -90px;
  span {
    height: 13px;
    width: 5px;
    background-color: #3082fd;
    display: inline-block;
    margin-right: 5px;
  }
}
.showImg {
  .el-form-item__content {
    display: flex;
    flex-wrap: wrap;
    .img {
      position: relative;
      height: 100px;
      width: 100px;
      margin: 20px 20px;
      img {
        height: 100px;
        width: 100px;
        border: 1px solid #c0ccda;
      }
      div {
        position: absolute;
        top: 0;
        right: 2px;
        height: 10px;
        width: 10px;
        z-index: 100;
      }
    }
    .addImg {
      display: inline-block;
      width: 100px;
      height: 100px;
      border: 1px dashed #c0ccda;
      text-align: center;
      line-height: 100px;
      cursor: pointer;
      margin-top: 20px;
      i {
        width: 50px;
        height: 50px;
        font-size: 20px;
        color: #c0ccda;
      }
    }
  }
}
</style>